import React from "react";
import { Container, Table } from "react-bootstrap";

function PastCompetitions() {
    return (
        <div>
            <h1>Past Competitions</h1>
            <p>Details about past competitions will be displayed here.</p>
        </div>
    );
} 

export default PastCompetitions;